<template>
  <div class="position-relative">
    <div v-if="isLoading || !territorialJurisdiction" class="spinner-overlay">
      <SpinnerVue />
    </div>
    <!-- NPG Form -->
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pt-6 pb-0">
        <h3 class="card-title">{{ $t("NPG Insertion") }}</h3>
      </div>
      <div v-if="territorialJurisdiction" class="card-body">
        <validation-observer ref="mainValidationObserver" v-slot="{ handleSubmit }">
          <validation-provider v-slot="{ errors }" :name="$t('Name')" rules="required">
            <euro-input v-model="form.name" :label="$t('Name') + '*'" :error-messages="errors"></euro-input>
          </validation-provider>
          <b-form-group id="input-group-description" :label="$t('Description')">
            <b-form-textarea id="input-description" v-model="form.description" name="description" rows="8" max-rows="8"
              class="">
            </b-form-textarea>
          </b-form-group>
          <validation-provider v-slot="{ errors }" :name="$t('Territorial Jurisdiction')" vid="territorial_jurisdiction"
            rules="required">
            <euro-select v-model="form.territorial_jurisdiction" :label="$t('Territorial Jurisdiction') + '*'"
              :error-messages="errors" :options="territorialJurisdictionSelectable"
              :placeholder="$t('Select a territorial jurisdiction')" searchable
              @change="clearAdministrativeStructure"></euro-select>
          </validation-provider>
          <validation-provider v-slot="{ errors }" :name="$t('Administrative Structure')" vid="administrative_structure"
            rules="required">
            <euro-select v-model="form.administrative_structure" :label="$t('Administrative Structure') + '*'"
              :error-messages="errors" :options="administrativeStructureSelectable"
              :placeholder="$t('Select an administrative structure')" searchable :disabled="!is_structure_available"
              @change="handleAdministrativeUpdate">
              <template #append>
                <b-button disabled>
                  <i class="flaticon2-plus"></i>
                </b-button>
              </template>
            </euro-select>
            <div v-if="loader" class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </validation-provider>
          <validation-provider v-slot="{ errors }" :name="$t('Legal Type')" vid="legal_type" rules="required">
            <euro-select v-model="form.legal_type" :label="$t('Legal Type') + '*'" :error-messages="errors"
              :options="legalTypeSelectable" :placeholder="$t('Select legal type')" searchable
              :disabled="!is_legal_and_category_available"></euro-select>
          </validation-provider>
          <validation-provider v-slot="{ errors }" :name="$t('Categories')" vid="categories" rules="required">
            <euro-select v-model="category" :label="$t('Categories') + '*'" :options="categoryList"
              :error-messages="errors" :placeholder="$t('Select one or more categories / jurisdictions')" multiple
              searchable :disabled="!is_legal_and_category_available"></euro-select>
          </validation-provider>
          <div class="form-group">
            <div class="row">
              <div class="col">
                <validation-provider v-slot="{ errors }" :name="$t('Valid From')" vid="valid_from"
                  rules="before_if:@valid_to">
                  <euro-date-picker v-model="form.valid_from" name="Valid From" vid="valid_from" :label="$t('Valid From')"
                    :error-messages="errors" class="mb-1"></euro-date-picker>
                </validation-provider>

                <div class="d-flex align-items-center">
                  <label class="small mb-0 mr-2 mt-1" for="input-validFromSwitch">{{
                    $t("Set validity end date")
                  }}</label>
                  <b-form-checkbox id="input-validFromSwitch" v-model="checked" name="check-button" switch size="lg">
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col">
                <validation-provider v-slot="{ errors }" :name="$t('Valid To')" vid="valid_to" rules="after:@valid_from">
                  <euro-date-picker v-model="form.valid_to" :label="$t('Valid To')" :error-messages="errors"
                    :disabled="!is_valid_to_enabled"></euro-date-picker>
                </validation-provider>
              </div>
            </div>
          </div>
          <b-form-group>
            <label for="tags-pills">{{ $t("Tags") }}</label>
            <b-form-tags v-model="form.tags" class="form-control form-control-solid h-auto custom-tag-input"
              input-id="tags-pills" tag-variant="success" size="md" separator="," placeholder=" "></b-form-tags>
            <b-form-text>
              {{ $t("Comma seperated tag list") }}
            </b-form-text>
          </b-form-group>

          <euro-file-input v-model="form.attachment" :label="$t('Attachment')" :error-messages="errors" accept=".pdf"
            :hint="$t('Accept .pdf - max size 20MB')"></euro-file-input>
          <b-form-file v-model="attachment2" :state="Boolean(attachment2)" :placeholder="$t('Drop your files here')"
            class="custom-file-upload"></b-form-file>
          <div class="pt-5 mt-5 d-flex justify-content-end">
            <b-button type="reset" variant="secondary" size="lg" @click="onReset">{{ $t("Cancel") }}</b-button>
            <b-button class="ml-2" :class="{ 'spinner spinner-light spinner-right': isSaving }" type="submit"
              variant="primary" size="lg" @click="handleSubmit(submit)">
              {{ npgId ? $t("Update") : $t("Save") }}
            </b-button>
          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SpinnerVue from "../../loaders/Spinner.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Swal from "sweetalert2";
import NpgAdministrativeStructureService from "@/core/services/npg/npg-administrative-structure.service";
import NpgService from "@/core/services/npg/npg.service";
import NpgCategoriesService from "@/core/services/npg/npg-categories.service";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    SpinnerVue,
  },

  data() {
    return {
      isLoading: true,
      isSaving: false,
      form: {
        name: "",
        description: "",
        valid_from: null,
        valid_to: null,
        tags: [],
        legislationattachment_set: [],
        territorial_jurisdiction: "",
        administrative_structure: "",
        legal_type: "",
        categories: [],
      },
      category: [],
      territorial_jurisdiction_list: [],
      administrative_structure_list: [],
      legal_type_list: [],
      categories_list: [],
      categoriesCollection: [],
      errors: "",
      is_valid_to_enabled: false,
      is_structure_available: false,
      is_legal_and_category_available: false,
      attachment: null,
      attachment2: null,
      loader: false,
    };
  },
  computed: {
    ...mapState("npg", ["territorialJurisdiction"]),
    ...mapGetters("npg", ["getTerritorialJurisdiction"]),
    territorialJurisdictionSelectable() {
      this.EnableAdministrativeStructure(this.form.territorial_jurisdiction);
      return this.getTerritorialJurisdiction;
    },

    administrativeStructureSelectable() {
      this.EnableLegalAndCategories(this.form.administrative_structure);
      return this.administrative_structure_list?.map(el => ({ text: el.name, value: el.id }));
    },

    legalTypeSelectable() {
      return this.legal_type_list?.map(el => ({ text: el.name, value: el.id }));
    },

    categoryList: {
      get() {
        return this.categories_list?.map(el => el.name);
      },
      set(val) {
        this.categories_list = val.map(el => ({ text: el.name, value: el.id }));
      },
    },
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val) {
          this.form.valid_to = "";
        }
        this.is_valid_to_enabled = val;
      },
    },
    npgId() {
      return this.$route.params.npgId;
    },
  },

  watch: {
    errorMessages: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.mainValidationObserver;
        const newErrors = {
          name: [...(val.name ?? [])],
          territorial_jurisdiction: [...(val.territorial_jurisdiction ?? [])],
          administrative_structure: [...(val.administrative_structure ?? [])],
          legal_type: [...(val.legal_type ?? [])],
          categories: [...(val.categories ?? [])],
          valid_from: [...(val.valid_from ?? [])],
          valid_to: [...(val.valid_to ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("NPG"), route: "manage" },
      { title: this.$t("Form") },
    ]);
  },

  async created() {
    await this.loadTerritorialDetails();
    if (this.npgId) {
      await this.loadNpgDetails();
    }
  },

  methods: {
    async loadTerritorialDetails() {
      if (!this.npgId) {
        this.isLoading = false;
      }
    },
    async EnableAdministrativeStructure(territorialJurisdiction) {
      if (territorialJurisdiction) {
        this.is_structure_available = true;
        this.loader = true;

        const administrativeStructure = await NpgAdministrativeStructureService.getByJurisdiction(
          territorialJurisdiction,
        );
        this.administrative_structure_list = administrativeStructure.results;
        if (!administrativeStructure.results.length) {
          this.form.administrative_structure = "";
        }
        this.loader = false;
      }
    },
    async EnableLegalAndCategories(structure) {
      if (structure) {
        let legalAndCategoryData = this.administrative_structure_list.filter(el => el.id == structure);
        this.legal_type_list = legalAndCategoryData[0]?.legal_type;
        this.categories_list = legalAndCategoryData[0]?.categories;
        this.is_legal_and_category_available = true;
      } else {
        this.handleAdministrativeUpdate();
      }
    },

    clearAdministrativeStructure() {
      this.form.administrative_structure = "";
      this.is_structure_available = false;
    },

    handleAdministrativeUpdate() {
      if (this.form.administrative_structure) {
        this.EnableLegalAndCategories(this.form.administrative_structure);
      } else {
        this.is_legal_and_category_available = false;
      }
      this.form.legal_type = "";
      this.category = "";
    },
    getCategoryId(categoryName) {
      this.form.categories = this.categories_list
        .filter(item => categoryName.includes(item.name))
        .map(category => category.id);
    },
    getCategoryName(categoryId) {
      this.form.categories = this.categories_list
        .filter(item => categoryId.includes(item.id))
        .map(category => category.name);
    },
    async submit() {
      this.isSaving = true;
      try {
        this.getCategoryId(this.category);
        if (this.npgId) {
          await NpgService.update(this.form, this.npgId);
          Swal.fire({
            title: this.$t("Success"),
            icon: "success",
            text: this.$t("NPG updated successfully"),
          }).then(() => this.$router.push({ name: "manage-npg" }));
        } else {
          await NpgService.create(this.form);
          Swal.fire({
            title: this.$t("Success"),
            icon: "success",
            text: this.$t("NPG created successfully"),
          }).then(() => this.$router.push({ name: "manage-npg" }));
        }
      } catch (err) {
        Swal.fire({
          title: "",
          text: err?.response?.data?.detail ?? this.$t("Something went wrong!"),
          icon: "error",
          confirmButtonClass: "btn btn-primary",
        });
      } finally {
        this.isSaving = false;
      }
    },
    onReset() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.$refs.mainValidationObserver.reset();
      this.form = {};
    },
    async loadNpgDetails() {
      try {
        const [npgData, categories] = await Promise.all([NpgService.getOne(this.npgId), NpgCategoriesService.getAll()]);
        this.categoriesCollection = categories;

        if (npgData.categories.length) {
          const categoriesFiltered = this.categoriesCollection.results.filter(({ id }) =>
            npgData.categories.includes(id),
          );
          this.category = categoriesFiltered.map(el => el.name);
        }
        this.form = npgData;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.errorCatch();
      }
    },
  },
};
</script>

<style>
.custom-file-upload .custom-file-input~.custom-file-label {
  border-color: #cbcbcb;
  border-width: 2px;
  border-style: dashed;
  background-color: #f1f1f1;
  height: 4rem;
  padding: 1rem;
}

.custom-file-upload .custom-file-input.is-valid~.custom-file-label {
  border-color: #1bc5bd;
  background-color: #fff;
}

.custom-file-upload .custom-file-input~.custom-file-label[data-browse]::after {
  display: none;
}

.custom-tag-input .badge-success {
  background-color: #41b883;
}

.spinner-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-block-start: 10%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.65);
}

.position-relative {
  position: relative;
}
</style>
