<template>
  <npg-form> </npg-form>
</template>

<script>
import NpgForm from "@/view/components/tables/npg/NpgForm.vue";

export default {
  components: {
    NpgForm,
  },
};
</script>
