var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative"
  }, [_vm.isLoading || !_vm.territorialJurisdiction ? _c('div', {
    staticClass: "spinner-overlay"
  }, [_c('SpinnerVue')], 1) : _vm._e(), _c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(_vm._s(_vm.$t("NPG Insertion")))])]), _vm.territorialJurisdiction ? _c('div', {
    staticClass: "card-body"
  }, [_c('validation-observer', {
    ref: "mainValidationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Name'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": _vm.$t('Name') + '*',
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "name", $$v);
                  },
                  expression: "form.name"
                }
              })];
            }
          }], null, true)
        }), _c('b-form-group', {
          attrs: {
            "id": "input-group-description",
            "label": _vm.$t('Description')
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "input-description",
            "name": "description",
            "rows": "8",
            "max-rows": "8"
          },
          model: {
            value: _vm.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "description", $$v);
            },
            expression: "form.description"
          }
        })], 1), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Territorial Jurisdiction'),
            "vid": "territorial_jurisdiction",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": _vm.$t('Territorial Jurisdiction') + '*',
                  "error-messages": errors,
                  "options": _vm.territorialJurisdictionSelectable,
                  "placeholder": _vm.$t('Select a territorial jurisdiction'),
                  "searchable": ""
                },
                on: {
                  "change": _vm.clearAdministrativeStructure
                },
                model: {
                  value: _vm.form.territorial_jurisdiction,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "territorial_jurisdiction", $$v);
                  },
                  expression: "form.territorial_jurisdiction"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Administrative Structure'),
            "vid": "administrative_structure",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": _vm.$t('Administrative Structure') + '*',
                  "error-messages": errors,
                  "options": _vm.administrativeStructureSelectable,
                  "placeholder": _vm.$t('Select an administrative structure'),
                  "searchable": "",
                  "disabled": !_vm.is_structure_available
                },
                on: {
                  "change": _vm.handleAdministrativeUpdate
                },
                scopedSlots: _vm._u([{
                  key: "append",
                  fn: function fn() {
                    return [_c('b-button', {
                      attrs: {
                        "disabled": ""
                      }
                    }, [_c('i', {
                      staticClass: "flaticon2-plus"
                    })])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.form.administrative_structure,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "administrative_structure", $$v);
                  },
                  expression: "form.administrative_structure"
                }
              }), _vm.loader ? _c('div', {
                staticClass: "text-center text-primary my-2"
              }, [_c('b-spinner', {
                staticClass: "align-middle mr-2"
              }), _c('strong', [_vm._v("Loading...")])], 1) : _vm._e()];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Legal Type'),
            "vid": "legal_type",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": _vm.$t('Legal Type') + '*',
                  "error-messages": errors,
                  "options": _vm.legalTypeSelectable,
                  "placeholder": _vm.$t('Select legal type'),
                  "searchable": "",
                  "disabled": !_vm.is_legal_and_category_available
                },
                model: {
                  value: _vm.form.legal_type,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "legal_type", $$v);
                  },
                  expression: "form.legal_type"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Categories'),
            "vid": "categories",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": _vm.$t('Categories') + '*',
                  "options": _vm.categoryList,
                  "error-messages": errors,
                  "placeholder": _vm.$t('Select one or more categories / jurisdictions'),
                  "multiple": "",
                  "searchable": "",
                  "disabled": !_vm.is_legal_and_category_available
                },
                model: {
                  value: _vm.category,
                  callback: function callback($$v) {
                    _vm.category = $$v;
                  },
                  expression: "category"
                }
              })];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "form-group"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Valid From'),
            "vid": "valid_from",
            "rules": "before_if:@valid_to"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('euro-date-picker', {
                staticClass: "mb-1",
                attrs: {
                  "name": "Valid From",
                  "vid": "valid_from",
                  "label": _vm.$t('Valid From'),
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.valid_from,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "valid_from", $$v);
                  },
                  expression: "form.valid_from"
                }
              })];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('label', {
          staticClass: "small mb-0 mr-2 mt-1",
          attrs: {
            "for": "input-validFromSwitch"
          }
        }, [_vm._v(_vm._s(_vm.$t("Set validity end date")))]), _c('b-form-checkbox', {
          attrs: {
            "id": "input-validFromSwitch",
            "name": "check-button",
            "switch": "",
            "size": "lg"
          },
          model: {
            value: _vm.checked,
            callback: function callback($$v) {
              _vm.checked = $$v;
            },
            expression: "checked"
          }
        })], 1)], 1), _c('div', {
          staticClass: "col"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Valid To'),
            "vid": "valid_to",
            "rules": "after:@valid_from"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('euro-date-picker', {
                attrs: {
                  "label": _vm.$t('Valid To'),
                  "error-messages": errors,
                  "disabled": !_vm.is_valid_to_enabled
                },
                model: {
                  value: _vm.form.valid_to,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "valid_to", $$v);
                  },
                  expression: "form.valid_to"
                }
              })];
            }
          }], null, true)
        })], 1)])]), _c('b-form-group', [_c('label', {
          attrs: {
            "for": "tags-pills"
          }
        }, [_vm._v(_vm._s(_vm.$t("Tags")))]), _c('b-form-tags', {
          staticClass: "form-control form-control-solid h-auto custom-tag-input",
          attrs: {
            "input-id": "tags-pills",
            "tag-variant": "success",
            "size": "md",
            "separator": ",",
            "placeholder": " "
          },
          model: {
            value: _vm.form.tags,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tags", $$v);
            },
            expression: "form.tags"
          }
        }), _c('b-form-text', [_vm._v(" " + _vm._s(_vm.$t("Comma seperated tag list")) + " ")])], 1), _c('euro-file-input', {
          attrs: {
            "label": _vm.$t('Attachment'),
            "error-messages": _vm.errors,
            "accept": ".pdf",
            "hint": _vm.$t('Accept .pdf - max size 20MB')
          },
          model: {
            value: _vm.form.attachment,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "attachment", $$v);
            },
            expression: "form.attachment"
          }
        }), _c('b-form-file', {
          staticClass: "custom-file-upload",
          attrs: {
            "state": Boolean(_vm.attachment2),
            "placeholder": _vm.$t('Drop your files here')
          },
          model: {
            value: _vm.attachment2,
            callback: function callback($$v) {
              _vm.attachment2 = $$v;
            },
            expression: "attachment2"
          }
        }), _c('div', {
          staticClass: "pt-5 mt-5 d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "type": "reset",
            "variant": "secondary",
            "size": "lg"
          },
          on: {
            "click": _vm.onReset
          }
        }, [_vm._v(_vm._s(_vm.$t("Cancel")))]), _c('b-button', {
          staticClass: "ml-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          attrs: {
            "type": "submit",
            "variant": "primary",
            "size": "lg"
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.submit);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.npgId ? _vm.$t("Update") : _vm.$t("Save")) + " ")])], 1)];
      }
    }], null, false, 3835593515)
  })], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }